import App from 'next/app';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import { setConfigs } from '../config/global';
import storeCreator from '../modules/redux/store';
import { DatadogInit } from '../monitor/data-dog';
import { initFirebase } from '../utils/firebase-config';
import theme from '../utils/themes';

import getConfig from './api/get-config';

import '../styles/styles.scss';

const queryClient = new QueryClient();

class MyApp extends App {
  static getInitialProps = async function () {
    const res = getConfig();

    return { secrets: res };
  };

  constructor(props) {
    super(props);
    setConfigs(this?.props?.secrets);
    initFirebase(this?.props?.secrets);
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    const { store, persistor } = storeCreator();
    const { Component, pageProps } = this?.props;
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              {this.props?.secrets?.APP_DATA_DOG_ENABLED?.toLowerCase() === 'true' && (
                <DatadogInit secrets={this.props?.secrets} />
              )}
              <Component {...pageProps} />
            </ThemeProvider>
          </PersistGate>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    );
  }
}

export default MyApp;

MyApp.propTypes = {
  // Do not use 'any' until and unless its extremely necessary.
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  secrets: PropTypes.any,
};
